<template>
    <Dialog 
        v-model:visible="entryDialog"
        :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
        :style="{ width: '40vw' }"
        :header="`Textelement bearbeiten`"
        :modal="true"
        class="p-fluid"
        @hide="hideDialog"
    >
        <div v-if="editMode">
            <div class="field">
                <p>Wird das Feld <b>Label</b> nicht ausgefüllt, wird der zugehörige IO-Name automatisch angezeigt</p>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="name">ID</label>
                    <InputText id="id" v-model.trim="ioDialogData.id" required="true" autofocus />
                </div>
                <div class="field col">
                    <label for="name">Name</label>
                    <InputText id="name" v-model.trim="ioDialogData.name" required="false" autofocus />
                </div>
            </div>

            <div class="formgrid grid">
                <div class="field col">
                    <label for="type">Position X</label>
                    <InputNumber id="posx" v-model="ioDialogData.x" mode="decimal" showButtons :min="0" />
                </div>
                <div class="field col">
                    <label for="type">Position Y</label>
                    <InputNumber id="posy" v-model="ioDialogData.y" mode="decimal" showButtons :min="0" />
                </div>
                <div class="field col">
                    <label for="type">Position Z</label>
                    <InputNumber id="posz" v-model="ioDialogData.z" mode="decimal" showButtons :min="0" />
                </div>
                <div class="field col">
                    <label for="type">Breite</label>
                    <InputNumber id="width" v-model="ioDialogData.width" mode="decimal" showButtons :min="1" />
                </div>
                <div class="field col">
                    <label for="type">Höhe</label>
                    <InputNumber id="height" v-model="ioDialogData.height" mode="decimal" showButtons :min="1" />
                </div>
                <div class="field col">
                    <label for="type">Rotation</label>
                    <InputNumber id="rotation" v-model="ioDialogData.rotation" mode="decimal" showButtons :min="-180" :max="180" />
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="background">Hintergrund</label>
                    <Dropdown id="style.background" v-model="ioDialogData.style.bgcol" :options="colors" optionLabel="label" optionValue="value">
                        <template #value="slotProps">
                            <div :class="'bg-' + slotProps.value">
                                <span>Hintergrund</span>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div :class="'bg-' + slotProps.option.value">
                                <span>{{ slotProps.option.label }}</span>
                            </div>
                        </template>
                    </Dropdown>
                </div>

                <div class="field col">
                    <label for="foreground">Vordergrund</label>
                    <Dropdown id="style.foreground" v-model="ioDialogData.style.fgcol" :options="colors" optionLabel="label" optionValue="value">
                        <template #value="slotProps">
                            <div :class="'fg-' + slotProps.value">
                                <span>Vordergrund</span>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div :class="'fg-' + slotProps.option.value">
                                <span>{{ slotProps.option.label }}</span>
                            </div>
                        </template>
                    </Dropdown>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <div class="checkbox">
                    <label for="arrayType">Schatten </label>
                    <Checkbox id="shadow" v-model="ioDialogData.style.shadow" :binary="true" />
                    </div>
                </div>
                <div class="field col">
                    <div class="checkbox">
                    <label for="arrayType">Outline </label>
                    <Checkbox id="outline" v-model="ioDialogData.style.outline" :binary="true" />
                    </div>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="type">Abgerundete Kanten</label>
                    <InputNumber id="cornerradius" v-model="ioDialogData.style.cornerRadius" mode="decimal" showButtons :min="0" />
                </div>
                <div class="field col" v-if="ioDialogData.style.outline">
                    <label for="type">Outline Stärke</label>
                    <InputNumber id="outlinewidth" v-model="ioDialogData.style.outlineStroke" mode="decimal" showButtons :min="0" />
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Löschen" icon="pi pi-trash" class="p-button-text fg-red" @click="saveDialog(true)" />
            <Button label="Duplizieren" icon="pi pi-copy" class="p-button-text fg-green" @click="saveDialog(false, true)" />
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDialog(false)" :disabled="opcTreeSelectedNodes.length === 0" />
        </template>
    </Dialog>
</template>

<script>
import { defineComponent } from 'vue';
// import helpers from "@/helpers";
import store from '@/store';
import SocketioService from "@/services/socketioService.js";
import { mapGetters } from 'vuex';
import { splitNodeId, combineNodeId } from "@/helpers";

export default defineComponent({
    name: 'dialogCanvasRect',
    setup() {},
    emits: ['entrySave', 'entryAbort'],
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        dialogData: {
            type: Object,
            required: true,
        },
        dialogObj: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            loading: true,
            editMode: true,
            entryDialog: false,
            ioDialogData: {
                source: {},
                metadata: {},
            },
            config_io: null,
            selectedScheme: null,
            selectedParameter: null,
            submitted: false,
            opcTree: [],
            opcTreeSelectedNodes: {},
            opcTreeSelection: {},
            opcTreeExpandedKeys: null,
            opcTreeSelectedNode: null,
            selectedOrderItem: null,
            typeOptions: [
                { label: 'statischer Text', value: 'text' },
                { label: 'variabler Text', value: 'textvar' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            getUserGroup: 'users/getUserGroups',
            getCategory: 'types/getCategory',
            getDepartments: 'types/getDepartments',
            getUnits: 'types/getUnits',
            getIcons: 'types/getIcons',
            getModules: 'opcua/getModules',
            getModuleScheme: 'opcua/getModuleScheme',
            getModuleTypes: 'opcua/getModuleTypes',
            getModuleBindings: 'opcua/getModuleBindings',
            getObjectFactor: 'getObjectFactor',
            getObjectDecimal: 'getObjectDecimal',
        }),
        ioObj: function () {
            return this.dialogObj;
        },
        entryDiag: function () {
            return this.showDialog;
        },
        // ioDialogData: function () {
        //   return this.dialogData;
        // },
        nodeEntry: function () {
            if (this.content.data.id) {
                return store.getters.getValue(this.content.data.id);
            } else return undefined;
        },
        nodes: function () {
            return store.getters.getIoTree;
        },
        colors: function () {
            return store.getters.getStyleColors;
        },
        patterns: function () {
            return store.getters.getStylePatterns;
        },
        currentModuleScheme: function () {
            if (this.selectedScheme !== null && this.getModuleScheme && this.getModuleScheme.length > 0) {
                const scheme = this.getModuleScheme.find(item => item.scheme === this.selectedScheme);
                if (Array.isArray(scheme.children)) {
                    return scheme.children;
                }
            }
            return null;
        },
    },
    created() {
        this.$store.dispatch('users/loadUserGroups');
        this.$store.dispatch('types/loadUnitTypes');
        this.$store.dispatch('types/loadCategoryTypes');
        this.$store.dispatch('types/loadDepartmentTypes');
        this.$store.dispatch('types/loadIconTypes');
        this.$store.dispatch('opcua/loadModules');
        this.$store.dispatch('opcua/loadModuleScheme');
        this.$store.dispatch('opcua/loadModuleTypes');
        this.$store.dispatch('opcua/loadModuleBindings');
    },
    mounted() {
        this.ioDialogData = this.dialogData;
        this.entryDialog = this.entryDiag;
        if (this.ioDialogData.data && this.ioDialogData.data.moduleScheme) this.selectedScheme = this.ioDialogData.data.moduleScheme;
        if (this.ioDialogData.z === undefined) this.ioDialogData.z = 1;
        if (this.ioDialogData.rotation === undefined) this.ioDialogData.rotation = 0;
        if (this.ioDialogData.style.cornerRadius === undefined) this.ioDialogData.style.cornerRadius = 0;
        console.log(this.ioDialogData);
        this.getParameterArray();
        this.getOpcDirectory();
    },
    beforeUnmount() {
        this.loading = false;
        this.ioDialogData = {};
        this.opcTree = [];
        this.opcTreeSelection = null;
        this.opcTreeExpandedKeys = null;
        this.opcTreeSelectedNode = null;
        this.selectedIOs = null;
        this.submitted = false;
        this.io_data = null;
        this.opcTreeSelectedNodes = [];
    },
    methods: {
        hideDialog() {
            this.$emit('entryAbort');
        },
        resetDialogData() {
            this.ioDialogData = {
                id: "",
                name: "",
                type: "",
                x: 0,
                y: 0,
                z: 0,
                style: {},
                components: {},
                data: {},
                extra: {}
            };
            this.selectedParameter = null;
            this.selectedScheme = null;
        },
        saveDialog(remove, copy) {
            this.submitted = true;

            if (this.ioDialogData.id !== "") {
                if (remove) {
                    this.ioDialogData.remove = true;
                }
                if (copy) {
                    this.ioDialogData.copy = true;
                }
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        async getOpcDirectory() {
            if (this.opcTree.length === 0) {
                this.loading = true;
                SocketioService.getOpcuaBrowse(null, (err, response) => {
                if (!err && response !== null && response.length > 0) {
                    response.forEach((tree) => {
                        this.opcTree.push(tree.children[0]);
                    });
                    this.loading = false;
                }
                });
            }
        },
        getParameterArray() {
            if (this.ioDialogData.data) {
                let _opcSel = {};
                let _opcExp = {};
                this.opcTreeSelectedNodes = { ...this.ioDialogData.data };

                let node = this.opcTreeSelectedNodes;
                const splitted = splitNodeId(node.key);
                const ns = splitted.ns;
                const root = splitted.root;
                const application = splitted.application;
                const program = splitted.program;
                if (root !== undefined) {
                    if (application !== undefined) _opcExp[`${combineNodeId(ns, root + '.' + application)}`] = true;
                    if (program !== undefined) _opcExp[`${combineNodeId(ns, root + '.' + application + '.' + program)}`] = true;
                    if (node.parent !== undefined) _opcExp[`${node.parent}`] = true;
                }
                _opcSel[node.key] = {checked: true, partialChecked: false};
                console.log(_opcSel)

                this.opcTreeSelection = { ..._opcSel };
                this.opcTreeExpandedKeys = { ..._opcExp };
            }
        },
        onOpcTreeSelect(node) {
            this.opcTreeSelectedNodes = { ...node };
            // this.opcTreeSelection.forEach((selection) => {
            //     if (selection.checked && !selection.partialChecked) {
            //         console.log(selection);
            //     }
            // });
            console.log(this.opcTreeSelection);
            this.opcTreeSelectedNode = { ...node };
            this.opcTreeSelectedNode.scheme = node.label;
            this.opcTreeSelectedNode.key = null;
            const splittedId = splitNodeId(node.key);
            // this.ioDialogData.moduleName = splittedId.moduleName;
            // this.ioDialogData.source.server = node.server;
            // this.ioDialogData.source.ns = splittedId.ns;
            // this.ioDialogData.source.identifier = splittedId.identifier;
            // this.ioDialogData.source.root = splittedId.root;
            // this.ioDialogData.source.application = splittedId.application;
            // this.ioDialogData.source.program = splittedId.program;
            
            this.$toast.add({
                severity: "success",
                summary: node.label,
                detail: `${node.server}@${splittedId.identifier}`,
                life: 3000,
            });
        },
        onOpcTreeUnselect(node) {
            this.opcTreeSelectedNodes = {};
            
            this.$toast.add({
                severity: "success",
                summary: node.label,
                detail: `${node.server}@${node.key}`,
                life: 3000,
            });
        },
        splitNodeIdLocal(nodeKey) {
            return splitNodeId(nodeKey);
        },
        onReorder(node) {
            console.log(node);
        },
        onSelectionChange(node) {
            this.selectedOrderItem = node.value[0];
            console.log(this.selectedOrderItem);
        },
    },
});
</script>
